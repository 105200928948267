.perks-and-discount-copy-code-btn {
    width: 100%;
    border-radius: 4px;
    background: #c0c9d8 !important;
    height: 42px;
    color: #1d2939;
    padding: 0;
    font-family: S-bold;
}

.perks-and-discount-copy-code-btn:focus,
.perks-and-discount-copy-code-btn:hover {
    background: inherit;
    color: inherit;
}
