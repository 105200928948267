
p{
    margin: unset;
}
.allproductcardv2__container{
    background-color: white;
    width: 321px;
    padding: 1rem;
    border-radius: 1rem;   
}
.allproductcardv2__container:hover{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.allproductcardv2__title_container_section{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.allproductcardv2__title_container_section p{
    font-family: '--general-sans-medium' !important;
    font-weight: 600;
    font-size: 1.125rem;
    color: #0B0A0A;
    transform: translateY(5px);
}


.allproductcardv2__content{
    font-family: '--general-sans-regular' !important;
    /* font-weight: 400; */
    font-size: 1rem;
    /* color: #0B0A0A; */
    color: #000000bf;
    padding: 0.5rem 0;
}

.allproductcardv2__usedby_container{
    padding: .5rem 0;
}
.allproductcardv2__usedby_container p{
    
    font-family: '--general-sans-medium' !important;
    font-weight: 500;
    font-size: 0.75rem;
    color: #A3A3A3;
}

.allproductcardv2__each_usedby img{
    width: 36px;
    border-radius: 50%;

}

.allproductcardv2__each_usedby{
    background-color: #f1f4fd;
    border: 5px solid white;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: -15px;
}

.allproductcardv2__each_usedby_container{
    display: flex;
    /* gap: -10px; */
}
.allproductcardv2__each_usedby:first-child{
    /* border: 1px solid green; */
    margin-left:0 !important;


}
.allproductcardv2__btn_container button{
    font-family: '--general-sans-semibold' !important;
    font-weight: 700;   
    font-size: .8rem;
    color: white;
    background-color: #4161DF;
    border-radius: 10px;
    display: block;
    padding: .7rem 0;
    width: 100%;
    border: transparent;
}
.allproductcardv2__btn_container{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-top: .5rem;
}