.first-letter {
    background-color: #daf1ee;
    color: #0b0a0a;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-right: 10px;
    line-height: 32px;
    font-weight: 700;
}

.create-api {
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 24.3px !important;
    border-radius: 16px;
    border: 1px;
    border-color: #e6e6e6;
}
.header-style {
    text-align: left;
    font-size: 24px !important;
    font-weight: 600;
    line-height: 32px;
}
.sub-text {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    color: #353535 !important;
}
.sub-header {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #0b0a0a;
}
.template-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 16px !important;
}
.template-text {
    font-size: 18px !important;
    line-height: 24px;
}
.temp {
    border-radius: 16px !important;
}
.btn-grey {
    background: #f4f4f4;
    color: #0b0a0a;
    line-height: 21.6px;
    align-items: center;
    width: 100%;
    border-radius: 16px;
    padding: 16px;
    font-size: 16px;
    font-weight: 700;
    margin-top: px;
}
.view-all-link {
    text-decoration: none;
    padding: 10px;
    background: #f4f4f4;
    color: #0b0a0a;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-left: 20px;
}
.temp-text {
    color: #353535;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dot {
    background: var(--Grey-Grey-80, #e3e3e3);
    width: 5px;
    height: 5px;
    margin: 0 6px;
    border-radius: 50%;
    display: inline-block;
}

.api-dot {
    background: var(--Grey-Grey-80, #e3e3e3);
    width: 10px;
    height: 10px;
    margin: 0 6px;
    border-radius: 50%;
    display: inline-block;
}
.endpoint-name {
    display: flex;
    align-items: center;
}

.endpoint-first-letter {
    background-color: green;
    color: white;
    padding: 4px;
    border-radius: 50%;
    margin-right: 8px;
    color: #0b0a0a;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
}

.status {
    border-radius: 8px;
    padding: 4px 8px;
    font-weight: bold;
}

.published {
    color: #32d583;
    background-color: #dafbeb;
    /* padding: 10px; */
}

.status.DRAFTS {
    color: #7d7d7d;
    background-color: #f4f4f4;
    /* padding: 10px; */
}

.menu-container {
    position: relative;
}

.menu-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.menu-options {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    z-index: 1000; /* Ensure the menu appears above other elements */
    min-width: 190px;
}

.menu-options li {
    list-style-type: none;
    padding: 8px 16px;
    font-size: 14px;
    color: #0b0a0a;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: left;
    justify-content: flex-start;
    border-bottom: 1px solid #ccc; /* Add a border line after each menu option */
}

.menu-options li:hover {
    background-color: #f0f0f0;
}

.menu-options li:last-child {
    border-bottom: none; /* Remove border line from the last menu option */
}

.menu-icon {
    margin-right: 12px; /* Adjust spacing between the image and text */
}
.table {
    border-collapse: separate;
    border-spacing: 0 10px; /* Space between rows */
    width: 100%;
}

.table thead th {
    text-align: left;
    padding: 10px;
}

.api-item {
    background: #e6e6e6;
    border: none; /* Remove border from row */
}

.api-item td {
    padding: 10px;
    background: #ffffff; /* Background color for cells */
    border: none; /* Remove border from individual cells */
}

/* Apply border-radius and border to the first and last td in each row */
.api-item td:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-top: 1px solid #e6e6e6; /* Apply top border */
    border-bottom: 1px solid #e6e6e6; /* Apply bottom border */
    border-left: 1px solid #e6e6e6; /* Apply left border */
}

.api-item td:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top: 1px solid #e6e6e6; /* Apply top border */
    border-bottom: 1px solid #e6e6e6; /* Apply bottom border */
    border-right: 1px solid #e6e6e6; /* Apply left border */
}

/* Apply border to the middle cells */
.api-item td:not(:first-child):not(:last-child) {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

.api-cell.endpoint-name {
    display: flex;
    align-items: center;
    flex-grow: 5;
}

.api-dot.published {
    background: #32d583;
}

.api-dot.DRAFTS {
    background-color: #7d7d7d;
}

.menu-container {
    position: relative;
}

.menu-icon {
    background: none;
    border: none;
    cursor: pointer;
}

.menu-options {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 150px; /* Adjust width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.menu-options li {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eaeaea; /* Add border between items */
}

.menu-options li:last-child {
    border-bottom: none; /* Remove border for the last item */
}

.menu-options li:hover {
    background-color: #f0f0f0; /* Highlight on hover */
}

.menu-icon {
    margin-right: 10px;
}

.no-api-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 300px;
    margin: 0 auto; /* Center the container horizontally */
}

.no-api-text {
    text-align: center;
    margin-top: 20px;
}
/* Modal container */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal content */
.modal-content {
    position: relative; /* Ensure child absolute elements are positioned relatively to this container */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

/* Close button container */
.close-con {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

/* Close button */
.close {
    cursor: pointer;
    font-size: 24px; /* Increased font size */
    border: 3px solid black; /* Added border */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; /* Position absolutely within the close-con */
    top: 0;
    right: 0;
}

.close:hover {
    background-color: #f1f1f1; /* Light gray background on hover */
}

/* Underline */
.underline {
    border-bottom: 1px solid #ccc;
    margin-top: 50px;
    margin-bottom: 20px;
}

.w-100 {
    width: 100%;
}

.py-3 {
    padding: 10px 0;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 8px;
}
.form-select,
input[type='text'] {
    margin-bottom: 10px;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 16px;
    width: 100%;
}

.api-link-container {
    display: flex;
    align-items: center;
    position: relative;
}

.api-link-container input {
    width: 100%;
    padding-right: 40px; /* Add space for the copy icon */
}

.copy-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #fff !important;
}

.response-structure {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding: 20px;
}
.resStru {
    background: #f4f4f4;
    border-radius: 8px;
    width: fit-content;
    padding: 10px;
}
.more {
    background: #e3e3e3;
    padding: 8px;
    border-radius: 8px;
    width: fit-content;
}
.error {
    color: red;
    font-size: 14px !important;
    margin-top: -5 px !important;
    margin-bottom: 10 px !important;
}

.form-steps {
    max-width: 420px;
}

.go-back-btn {
    text-decoration: none;
}

/* Custom select styling */
.custom-select {
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: 30px;
    max-height: 100px !important;
    border-radius: 16px;
}
.custom-select select {
    border-radius: 16px;
    padding: 16px;
}

.custom-select select::-ms-expand {
    display: none;
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.api-link-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 20px;
}

.api-link-container input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 16px;
}

.copy-icon {
    cursor: pointer;
    padding: 10px;
    border-left: none;
    border-radius: 0 4px 4px 0;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.user-details {
    display: flex;
    flex-direction: row;
}
.date-user {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    gap: 10px;
}
.seperator {
    background: black;
    width: 10px;
    height: 10px;
    margin: 6px;
    margin-top: -12px;
    border-radius: 50%;
}

.sub-text span {
    margin-right: 10px;
}

.copy-link {
    padding: 10px 20px 0px 10px;
    border: 2px solid #7d7d7d;
    border-radius: 16px;
    align-items: center;
    margin-top: 3px;
}
.endpoint-color-0 {
    color: red;
}

.endpoint-color-1 {
    color: blue;
}

.cont {
    background: linear-gradient(#ffffff 0 0) padding-box,
        linear-gradient(to top, #9c20aa, #fb3570) border-box;
    padding: 10px;
    border: 2px solid transparent;
    border-radius: 16px;
}

/* create form */
.selected-endpoint {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.selected-endpoint-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    gap: 10px;
    background-color: #f0f0f0; /* Adjust the background color as needed */
}

.clearBtn:hover {
    color: #4f4f4f;
    background: #b9b0b0;
    border: 2px solid #4f4f4f;
}

.placeholders {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    pointer-events: none;
}

.json-container {
    background-color: #333;
    color: #4f4f4f;
    padding: 20px;
    border-radius: 16px;
    position: relative;
    height: 500px;
    overflow: auto;
    margin-top: 10px;
    background: #0b0a0a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.clearBtn {
    align-self: flex-start;
    border: 2px solid #4f4f4f;
    background: transparent;
    color: #4f4f4f;
    cursor: pointer;
    border-radius: 16px;
    padding: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.copy-icon {
    gap: 10px;
    color: white;
    background: #1d1d1d;
    border-radius: 16px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
}

.forms img {
    margin-right: 5px !important;
}

.user-details {
    display: flex;
    flex-direction: row;
}
.date-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    gap: 10px;
}
.seperator {
    background: black;
    width: 10px;
    height: 10px;
    margin: 6px;
    margin-top: -12px;
    border-radius: 50%;
}

.sub-text span {
    margin-right: 10px;
}

.copy-link {
    padding: 10px 20px 0px 10px;
    border: 2px solid #7d7d7d;
    border-radius: 16px;
    align-items: center;
    margin-top: 3px;
}
.endpoint-color-0 {
    color: red;
}

.endpoint-color-1 {
    color: blue;
}

.cont {
    background: linear-gradient(#ffffff 0 0) padding-box,
        linear-gradient(to top, #9c20aa, #fb3570) border-box;
    padding: 10px;
    border: 2px solid transparent;
    border-radius: 16px;
}
.btn-secondary {
    width: fit-content;
    align-items: center;
    align-content: center;
    justify-content: center;
}
/* create form */

.selected-endpoints {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the gap between items as needed */
}

.endpoint-item {
    display: inline-flex;
    margin: 5px; /* Adjust the margin to create space between items */
}
.endpoint-item img {
    margin-left: 7px;
}

.greyBGs {
    padding: 5px 20px 5px 20px;
    background: #e3e3e3;
    border-radius: 8px; /* Adjust border radius as needed */
    text-align: center; /* Center the text within the greyBG div */
    white-space: nowrap; /* Prevent text from wrapping within the item */
}
.greyBG {
    color: #0b0a0a;
    background: #e3e3e3;
    width: fit-content !important;
    padding: 5px;
    border-radius: 8px;
    font-weight: 500;
    padding: 5px 30px 5px 30px;
    margin: 10px 0;
    border: none;
}

.clearBtn:hover {
    color: #4f4f4f;
    background: #b9b0b0;
    border: 2px solid #4f4f4f;
}

.placeholders {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    pointer-events: none;
}

.json-container {
    background-color: #333;
    color: #4f4f4f;
    padding: 20px;
    border-radius: 16px;
    position: relative;
    height: 500px;
    overflow: auto;
    margin-top: 10px;
    background: #0b0a0a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.clearBtn {
    align-self: flex-start;
    border: 2px solid #4f4f4f;
    background: transparent;
    color: #4f4f4f;
    cursor: pointer;
    border-radius: 16px;
    padding: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.copy-icon {
    gap: 10px;
    color: white;
    background: #1d1d1d;
    border-radius: 16px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
}

.forms img {
    margin-right: 5px !important;
}

.filter-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.filter-label-content {
    display: flex;
    align-items: center;
}

.filter-label-content img {
    margin-right: 10px;
}

.search-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.search-input {
    padding-left: 30px; /* Adjust based on icon size */
}

.search-icon,
.clear-icon {
    position: absolute;
    left: 10px;
    cursor: pointer;
}

.clear-icon {
    right: 10px;
    left: auto;
}

.filter-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.filter-label-content {
    display: flex;
    align-items: center;
}

.filter-label-content img {
    margin-right: 10px;
}

.search-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.search-input {
    padding-left: 35px; /* Increase padding to add space between the icon and placeholder text */
}

.search-icon,
.clear-icon {
    position: absolute;
    cursor: pointer;
}

.search-icon {
    left: 10px; /* Position search icon */
}

.clear-icon {
    right: 10px; /* Position clear icon */
}

.no-result {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 70px;
    align-items: center;
    align-content: center;
}
.no-result img {
    width: 40px;
    height: 40px;
}

.btn-light-green {
    border-radius: 16px;
}

.usetemplate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* styles.css */

.ai-chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 20px;
    background-color: #f5f5f5;
}

.chat-box {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: white;
    margin-bottom: 20px;
}

.message {
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    max-width: 55%;
    word-wrap: break-word;
}

.message.user {
    margin-left: auto;
    text-align: right;
    border-radius: 16px 16px 0 16px;
    margin-top: 20px;
}

.message.ai {
    margin-right: auto;
    text-align: left;
    border-radius: 16px 16px 16px 0;
    margin-top: 20px;
}

.input-box {
    display: flex;
    position: relative;
    width: 100%;
}

.input-box input {
    flex: 1;
    padding: 10px 40px 10px 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.input-box span {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    display: inline-block;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
}

.card-body {
    position: relative;
    padding: 20px;
    border-radius: 16px !important;
}
.card {
    border-radius: 16px;
}

.card-body hr {
    margin: 0 -20px; /* Ensure the hr spans the full width */
    border: none;
    border-top: 2px solid #ccc;
    margin-bottom: 20px;
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

/* .first-letter {
    background-color: #daf1ee;
    color: #0b0a0a;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-right: 10px;
    line-height: 32px;
    font-weight: 700;
} */