.slider-container {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  
  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 4px;
    background: #ddd;
    outline: none;
    /* opacity: 0.7;
    transition: opacity 0.2s; */
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    position: relative;
    z-index: 400;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #2F9D92;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #4CAF50;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  }
  
  .value {
    margin-top: 10px;
    font-size: 1.2em;
    color: #333;
  }
  