.perks-and-discount-generate-code-btn {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #8193b1 !important;
    background: none !important;
    height: 42px;
    color: #003e51;
    padding: 0;
}

.perks-and-discount-generate-code-btn:focus,
.perks-and-discount-generate-code-btn:hover {
    background: inherit;
    color: inherit;
}
